import React from 'react';
import { Row, Card, Col, ListGroup } from 'react-bootstrap';

const Experience = () => {

    function getDiffWorkTime(){
        const firstDate = new Date("09/11/2024");
        const secondDate = new Date();

        const yearDiff = secondDate.getFullYear() - firstDate.getFullYear();
        const monthDiff = secondDate.getMonth() - firstDate.getMonth();

        const totalMonthsDiff = yearDiff * 12 + monthDiff;

        return totalMonthsDiff;
    }

    return (
        <div style={{ paddingTop: '1rem', backgroundColor: '#f9f9f9' }}>
            <h3 style={{ marginLeft: '1rem' }}>Experiencia Profesional</h3>
            <Row style={{ margin: '1rem' }}>
                <Col md={6} style={{ padding: '0.5rem'}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Basetis - Senior FullStack Developer</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Febrero 2019 - Enero 2023 (4 años) | Barcelona, Cataluña, España</Card.Subtitle>
                            <Card.Text>
                                Desarrollo fullStack de aplicaciones y gestión del proyecto
                            </Card.Text>
                            <ListGroup variant="flush">
                                <ListGroup.Item>Spring Boot</ListGroup.Item>
                                <ListGroup.Item>Spring Data JPA</ListGroup.Item>
                                <ListGroup.Item>React</ListGroup.Item>
                                <ListGroup.Item>SQL y DDBB</ListGroup.Item>
                                <ListGroup.Item>SOLID</ListGroup.Item>
                                <ListGroup.Item>Java 8 - 16 / Git</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} style={{ padding: '0.5rem'}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Basetis - Project Managment / Arquitecto SW</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Febrero 2023 - Enero 2024 (1 años) | Barcelona, Cataluña, España</Card.Subtitle>
                            <Card.Text>
                                Gestión de proyecto de un equipo de Desarollo
                            </Card.Text>
                            <ListGroup variant="flush">
                                <ListGroup.Item>Scrum Master</ListGroup.Item>
                                <ListGroup.Item>Metodologías Agiles</ListGroup.Item>
                                <ListGroup.Item>Project</ListGroup.Item>
                                <ListGroup.Item>Liderazgo de equipos</ListGroup.Item>
                                <ListGroup.Item>Arquitectura de API's</ListGroup.Item>
                                <ListGroup.Item>Contacto con cliente final</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} style={{ padding: '0.5rem'}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Volkswagen Group Services Barcelona - Ingeniero de Software</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Enero 2024 - Septiembre 2024 (9 meses) | Martorell, Cataluña, España</Card.Subtitle>
                            <Card.Text>
                                Desarrollador de aplicaciones para el HMI (radio) de los coches de la marca
                            </Card.Text>
                            <ListGroup variant="flush">
                                <ListGroup.Item>Spring Boot</ListGroup.Item>
                                <ListGroup.Item>Kotlin</ListGroup.Item>
                                <ListGroup.Item>Desarrollo Android</ListGroup.Item>
                                <ListGroup.Item>Figma</ListGroup.Item>
                                <ListGroup.Item>Dagger Hilt</ListGroup.Item>
                                <ListGroup.Item>PostgreSQL</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} style={{ padding: '0.5rem'}}>
                    <Card>
                    <Card.Body>
                            <Card.Title>Minsait - Ingeniero de Software</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">{`Septiembre 2024 - Presente (${getDiffWorkTime()} meses) | Barcelona, Cataluña, España`}</Card.Subtitle>
                            <Card.Text>
                                Desarrollador de front-end para aplicativos de La Caixa
                            </Card.Text>
                            <ListGroup variant="flush">
                                <ListGroup.Item>React</ListGroup.Item>
                                <ListGroup.Item>JavaScript</ListGroup.Item>
                                <ListGroup.Item>GitFlow</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Experience;
