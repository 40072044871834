import react from 'react'

import { Row } from 'react-bootstrap'

const Education = () => {
    return (
        <div style={{ paddingTop: '1rem', backgroundColor: '#f9f9f9' }}>
        <Row style={{ backgroundColor: '#f9f9f9' }}>
            <div style={{ paddingTop: '1rem' }}>
                <h3 style={{ marginLeft: '1rem' }}>Educación</h3>
                <ul>
                    <li>
                        <h3>OBS Business School - Master en Project Management</h3>
                        <p>Septiembre 2021 - Agosto 2022</p>
                    </li>
                    <li>
                        <h3>Universitat de Barcelona - Grado en Ingeniería Electrónica de Telecomunicaciones</h3>
                        <p>2014 - 2020</p>
                    </li>
                </ul>
            </div>
        </Row>
        </div>

    )
}

export default Education;