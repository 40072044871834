import React from 'react';
import './Home.css';
import ProfilePicture from '../assets/profile.jpg'
import { IconExternalLink, IconGithub, IconLinkedin, IconMail, IconMapPin, IconPhone } from '../components/Icons';
import HeroComponent from '../components/Hero/Hero';
import AboutMe from '../components/AboutMe/AboutMe';
import Experience from '../components/Experience/Experience';
import Education from '../components/Education/Education';

export default function Home() {

  function getDiffWorkTime(){
    const firstDate = new Date("09/11/2024");
    const secondDate = new Date();
    
    const yearDiff = secondDate.getFullYear() - firstDate.getFullYear();
    const monthDiff = secondDate.getMonth() - firstDate.getMonth();
    
    const totalMonthsDiff = yearDiff * 12 + monthDiff;

    return totalMonthsDiff;
  }

  return (
    <div style={{ backgroundColor: '#f9f9f9' }}>
      <HeroComponent/>
      <AboutMe/>
      <Experience/>
      <Education/>  

      <section className="skills">
        <div className="container">
          <h2>Habilidades</h2>
          <div className="skills-grid">
            {['Programación en JavaScript', 'React', 'Node.js', 'Python', 'Gestión de proyectos', 'Scrum', 'Docker', 'AWS'].map((skill) => (
              <div key={skill} className="skill-item">{skill}</div>
            ))}
          </div>
        </div>
      </section>

      <section id="contact" className="contact">
        <div className="container">
          <h2>Contacto</h2>
          <div className="contact-content">
            <div className="contact-info">
              <p>¿Interesado en colaborar o trabajar juntos? ¡Contáctame!</p>
              <div className="contact-details">
                <div className="contact-item">
                  <IconMail />
                  <span>dsanroga@gmail.com</span>
                </div>
                <div className="contact-item">
                  <IconPhone />
                  <span>+34 637 203 159</span>
                </div>
                <div className="contact-item">
                  <IconMapPin />
                  <span>Barcelona, España</span>
                </div>
              </div>
              <div className="social-links">
                <a href="https://linkedin.com/in/tuperfil" aria-label="LinkedIn">
                  <IconLinkedin />
                </a>
                <a href="https://github.com/tuusuario" aria-label="GitHub">
                  <IconGithub />
                </a>
              </div>
            </div>
            <form className="contact-form">
              <input type="text" placeholder="Nombre" required />
              <input type="email" placeholder="Email" required />
              <textarea placeholder="Mensaje" rows={4} required></textarea>
              <button type="submit" className="btn btn-primary">Enviar Mensaje</button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}
