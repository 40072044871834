import React from 'react'
import { Row, Card } from 'react-bootstrap';

const AboutMe = () => {
    return (
        <Row style={{backgroundColor: '#f9f9f9'}}>
            <div style={{paddingTop: '1rem'}}>
            <h3 style={{marginLeft: '1rem'}}>Sobre Mí</h3>
                <Card  style={{margin: '1rem'}}>
                    <Card.Body>
                        <Card.Text>
                        Soy Ingeniero Electrónico especializado en Telecomunicaciones, con experiencia en desarrollo de software. 
            Mi habilidad para resolver problemas numéricos y lógicos, junto con mis conocimientos en circuitos electrónicos 
            y física, me permiten ofrecer soluciones tecnológicas avanzadas. También cuento con experiencia en gestión de 
            proyectos como Scrum Master certificado.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>          
        </Row>
    )
}

export default AboutMe;