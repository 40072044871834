import React from "react";
import ProfilePicture from '../../assets/profile.jpg'

import { Col, Row, Image } from "react-bootstrap";

const HeroComponent = () => {
    return (
        <Row style={{ backgroundColor: '#2a00b3', padding: '40px' }}>
            <Col md={5} xs={8} className="d-flex justify-content-center" style={{ height: '100%' }}>
                <Image src={ProfilePicture} roundedCircle style={{
                    width: '55vh',
                    height: 'max-content', padding: '20px'
                }} />
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
                <div style={{color: "#FFFFFF"}}>          
                    <h1>Daniel Sanroman</h1>
                    <p>Ingeniero de Software Full-Stack | Project Manager | Ingeniero Electrónico de Telecomunicaciones | Scrum Master Certificado</p>
                    <div className="hero-buttons">
                        <a href="#contact" className="btn btn-primary">Contáctame</a>
                        <a href="#projects" className="btn btn-secondary">Ver Proyectos</a>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default HeroComponent;